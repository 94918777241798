import { Box, Button, Link, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Head from "next/head";
import { useCallback, useEffect, useState } from "react";

// import heroImg from "../public/img/homepage/hero-4096.png";

import InternalLink from "@cosy/components/InternalLink";
import { CustomThemeProvider } from "@cosy/hooks/use_custom_theme";
import useLastVisitedWorkspace from "@cosy/hooks/use_last_visited_workspace";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  },
  // contentContainer: {
  //   transition: "all 50ms ease-out",
  //   padding: theme.spacing(5),
  //   [theme.breakpoints.up("md")]: {
  //     padding: theme.spacing(10),
  //     width: "48%",
  //     maxWidth: 800,
  //     position: "relative",
  //     top: "5vh",
  //   },
  // },
  imageContainer: {
    transition: "all 50ms ease-in-out",
    width: "110%",
    height: "110%",
    position: "fixed",
    top: "-2.5%",
    left: "-2.5%",
  },
  userSection: {
    position: "absolute",
    top: theme.spacing(12),
    right: theme.spacing(2),
    zIndex: 2,
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(10),
    },
  },
}));

const metaDescription = "Cosy, Inc.";

const _HOMEPAGE_THEME = {
  typography: {
    h1: {
      fontSize: "4rem",
      lineHeight: 0.9,
      letterSpacing: "-0.025em",
    },
    body1: {
      fontSize: "1.2rem",
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#ff1559",
    },
    secondary: {
      main: "#e3e3e5",
    },
    background: {
      default: "#5F2167",
    },
  },
};

export default function HomePage() {
  return (
    <CustomThemeProvider initialValue={_HOMEPAGE_THEME}>
      <CssBaseline />
      <HomePageContent />
    </CustomThemeProvider>
  );
}

function HomePageContent() {
  const classes = useStyles();

  const [xFromCenter, setXFromCenter] = useState(0);
  const [yFromCenter, setYFromCenter] = useState(0);

  const parallax = useCallback((e) => {
    setXFromCenter(e.view.innerWidth / 2 - e.clientX);
    setYFromCenter(e.view.innerHeight / 2 - e.clientY);
  }, []);

  // We’d redirect if they had an auth token, so this is only to display a Login
  // link if they’ve logged in before.
  const [lastVisitedWorkspace] = useLastVisitedWorkspace();

  useEffect(() => {
    document.addEventListener("mousemove", parallax);
    return () => document.removeEventListener("mousemove", parallax);
  });

  const contentTranslate = useParallaxTransform(
    xFromCenter,
    yFromCenter,
    0.005
  );

  const imageTranslate = useParallaxTransform(xFromCenter, yFromCenter, 0.01);

  return (
    <>
      <HeadTags />

      <div className={classes.container}>
        <div
          className={classes.imageContainer}
          style={{
            transform: imageTranslate,
          }}
        ></div>
        <div
          style={{
            transform: contentTranslate,
          }}
        >
          <Box>
            <img
              src="/img/homepage/texture.svg"
              alt="Cosy logo"
              width="228"
              height="60"
              style={{ display: "block", margin: "0 auto" }}
            />
            <Typography
              variant="body2"
              style={{ marginTop: 30, opacity: 0.8, textAlign: "center" }}
            >
              Cosy is an indie software company by{" "}
              <Link href="https://davidkaneda.com">David Kaneda</Link>.
              <br /> We make lovely little apps, like{" "}
              <Link href="https://boo.ai">Boo</Link>.
            </Typography>
          </Box>
        </div>
      </div>
      {!!lastVisitedWorkspace && (
        <div className={classes.userSection}>
          <InternalLink
            href={`/login`}
            linkComponent={Button}
            linkProps={{
              variant: "outlined",
            }}
          >
            Login
          </InternalLink>
        </div>
      )}
    </>
  );
}

function HeadTags() {
  return (
    <Head>
      <title>Cosy · Company home screen &amp; workplace search</title>
      <link
        href="https://www.googletagmanager.com"
        rel="preconnect"
        crossOrigin
      />
      <link
        href="https://www.google-analytics.com"
        rel="preconnect"
        crossOrigin
      />

      <meta name="description" content={metaDescription} />
      <meta property="og:title" content="Cosy" key="title" />
      <meta
        property="og:description"
        content={metaDescription}
        key="description"
      />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_HOST}/img/favicon/og.png`}
      />
      <meta
        property="og:url"
        content={process.env.NEXT_PUBLIC_HOST}
        key="url"
      />
      <meta property="og:site_name" content="Cosy" />

      <meta name="twitter:site" content="@cosy_app" />
      <meta name="twitter:card" content="summary_large_image" />

      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.GA_TRACKING_ID}');
        `,
        }}
      />
    </Head>
  );
}

function useParallaxTransform(xOffset, yOffset, scale) {
  return `translate3d(${xOffset * scale}px,${yOffset * scale}px,0)`;
}

HomePage.authRequired = false;
