import Link from "@material-ui/core/Link";
import NextLink from "next/link";
import { forwardRef } from "react";

import internalHref from "@cosy/lib/formatters/internal-href";

export default forwardRef(function InternalLink(
  {
    href = "/",
    passHref = true,
    linkComponent = Link,
    linkProps = {},
    children,
    ...extraProps
  },
  ref
) {
  const LinkComponent = linkComponent;

  return (
    <NextLink
      href={internalHref(href)}
      passHref={passHref}
      ref={ref}
      {...extraProps}
    >
      <LinkComponent {...linkProps}>{children}</LinkComponent>
    </NextLink>
  );
});
