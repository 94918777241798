/**
 * Function to retrive an href that can be safely used in the app
 * and in the chrome extension.
 *
 * @param {string} href
 * @returns {string}
 */
export default function internalHref(href) {
  return `${
    process.env.CHROME_EXTENSION ? process.env.NEXT_PUBLIC_HOST : ""
  }${href}`;
}
